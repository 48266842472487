window.showLoading = function () {
    $('#loading').addClass('show');
};
window.hideLoading = function () {
    $('#loading').removeClass('show');
};
window.swalConfirm = function (callback) {
    let options = {
        title: 'Bạn có chắc không?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy'
    }
    Swal.fire(options).then(callback)
}



$(function () {
    const numberFormat = new Intl.NumberFormat('vi-VI');
    $('.card-dropdown .card-dropdown-header').on('click',function () {
        $(this).parent(".card-dropdown").toggleClass("open");
    })
    $('.mobile-close,.mobile-menu-backdrop').on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();
        const selector = $(this).attr('data-target-close');
        $(selector).removeClass('show');
        $('body').removeClass('overflow-hidden');
    });
    $('.mobile-menu-toggler').on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();
        const selector = $(this).attr('data-target');
        $(selector).addClass('show');
        $('body').addClass('overflow-hidden');
    });
    $('.mobile-menu-item.children-dropdown .mobile-menu-item-link').on('click', function (event) {
        $(this).parent('.mobile-menu-item.has-children').toggleClass('open')
    })
    $('.toggle-show').on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();
        const selector = $(this).attr('data-target');
        $(selector).toggleClass('show');
    });
    document.addEventListener('mousedown', function (event) {
        const select = document.querySelector('.popup-box.show');
        if (select && !select.contains(event.target)) {
            select.classList.remove('show');
            event.preventDefault();
            event.stopPropagation();
        }
    });


    // input range
    function getVals() {
        const parent = this.parentNode;
        const slides = parent.getElementsByTagName('input');
        const labelMin = parent.querySelector('.tool-min');
        const labelMax = parent.querySelector('.tool-max');
        let slide1 = parseFloat(slides[0].value);
        let slide2 = parseFloat(slides[1].value);
        if (slide1 > slide2) {
            const tmp = slide2;
            slide2 = slide1;
            slide1 = tmp;
            // slides[0].value = slide1;
            // slides[1].value = slide2;
        }

        const unit = slides[0].getAttribute('data-unit');

        const width = slides[0].offsetWidth;
        const minStep = slides[0].getAttribute('min');
        const maxStep = slides[0].getAttribute('max');
        const valuePerPx = (width - 16) / (maxStep - minStep);
        if (labelMin) {
            labelMin.innerHTML = numberFormat.format(slide1) + unit;
            labelMin.style.left = `${((slide1 - minStep) * valuePerPx + 10)}px`;
        }
        if (labelMax) {
            labelMax.innerHTML = numberFormat.format(slide2) + unit;
            labelMax.style.left = `${((slide2 - minStep) * valuePerPx + 14)}px`;
        }
    }

    function inputRangeChange() {
        const parent = this.parentNode;
        const slides = parent.getElementsByTagName('input');
        let slide1 = parseFloat(slides[0].value);
        let slide2 = parseFloat(slides[1].value);
        if (slide1 > slide2) {
            const tmp = slide2;
            slide2 = slide1;
            slide1 = tmp;
            slides[0].value = slide1;
            slides[1].value = slide2;
        }
    }

    const sliderSections = document.getElementsByClassName('range-slider');
    for (let x = 0; x < sliderSections.length; x++) {
        const labelMin = document.createElement('label');
        const labelMax = document.createElement('label');
        labelMin.classList.add('tool-min');
        labelMax.classList.add('tool-max');
        sliderSections[x].append(labelMin, labelMax);
        const sliders = sliderSections[x].getElementsByTagName('input');
        for (let y = 0; y < sliders.length; y++) {
            if (sliders[y].type === 'range') {
                sliders[y].oninput = getVals;
                sliders[y].onchange = inputRangeChange;
                sliders[y].dispatchEvent(new Event('input'));
            }
        }
    }
    // input range


    // slide
    $('.product-slide.col3 .product-slide__body').owlCarousel({
        loop: true,
        margin: 24,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        navText: ['<i class="fa-solid fa-angle-left"></i>', '<i class="fa-solid fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 2,
                margin: 8,
                nav: true,
                dots: false,
            },
            768: {
                items: 3,
                margin: 24,
                nav: true,
                dots: false,
            },
            1440: {
                items: 4,
                margin: 24,
                nav: true,
                dots: false,
            }
        }
    });
    $('.product-slide.col4 .product-slide__body').owlCarousel({
        loop: true,
        margin: 24,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        navText: ['<i class="fa-solid fa-angle-left"></i>', '<i class="fa-solid fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 2,
                margin: 8,
                nav: true,
                dots: false,
            },
            768: {
                items: 3,
                margin: 24,
                nav: true,
                dots: false,
            }
        }
    });
    $('.article-slide .article-slide__body').owlCarousel({
        loop: true,
        margin: 24,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        navText: ['<i class="fa-solid fa-angle-left"></i>', '<i class="fa-solid fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 1,
                margin: 0,
                nav: true,
                dots: false
            },
            768: {
                items: 2,
                margin: 8,
                nav: true,
                dots: false
            },
            1024: {
                items: 3,
                margin: 24,
                nav: true,
                dots: false
            },
            1440:{
                items: 4,
                margin: 24,
                nav: true,
                dots: false
            }
        },
    });
    // slide
})
